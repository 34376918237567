import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as fileCards from '../../../assets/flashcards.data.json';

@Injectable({
  providedIn: 'root'
})
export class FlashcardsService {
  private readonly STORAGE_KEY: string = 'experiments-flashcards';
  private _tags: Tag[];
  private _lastTag: Tag;

  constructor() { 
    let data = (JSON.parse(localStorage.getItem(this.STORAGE_KEY)) || fileCards).tags;
    this._tags = data
      .map(x => new Tag(x.name, 
        new HSLA(
          x._color.hue, 
          x._color.saturation, 
          x._color.lightness, 
          x._color.opacity)));
    this._lastTag = this._tags[this._tags.length - 1];
  };

  public getCards(): Card[] {
    let data = (JSON.parse(localStorage.getItem(this.STORAGE_KEY)) || fileCards).cards;
    let cards = data.map(x => new Card(x.term, x.definition, x._tags.map(t => this.addTag(t))));
    return cards;
  }

  public export(cards: Card[]) {
    let payload = {
      tags: this._tags,
      cards: naturalSort(cards, ['tags', 0, 'name'])
    }
    console.log(payload);
    return saveAs(
      new Blob([JSON.stringify(payload, null, 2)], { type: 'JSON' }), 'flashcards.json'
    );
  }

  public save(cards: Card[]) {
    let payload = {
      tags: this._tags,
      cards: naturalSort(cards, ['tags', 0, 'name'])
    }
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(payload));
  }

  public get tags() {
    return this._tags;
  }

  public addTag(tag: Tag): Tag {
    let found = this._tags.find(x => x.name == tag.name);
    if(found) {
      this._lastTag = found;
      return found;
    } else {
      this._tags.push(tag);
      this._tags = naturalSort(this._tags, ['name']);
      this._lastTag = tag;
      return tag;
    }
  }

  getLastTag(): string {
    return this._lastTag.name;
  }

  autoName(): string {
    let matches = naturalSort(this._tags.filter(x => {
      if(x.name.match(/Unit [0-9]+/))
        return x;
    }), ['name']);
    let last = 0;
    matches.find(x => {
      let numeral = extractNumeral(x.name);
      last++;
      if(numeral === last) {
        return false;
      } else {
        return true;
      }
    });
    return `Unit ${last + 1}`;
  }
}

export function extractNumeral(input: string): number {
  return parseInt(input.replace(/[^0-9]/g, ''));
}

export function getNestedObject(nestedObj, pathArr) {
  let nested = pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, 
    nestedObj);
  return nested;
}

export class Card {
  private _color: HSLA;
  private _tags: Tag[];
  constructor(public term: string, public definition: string, tags?: Tag[]) {
    this._tags = tags.slice() || [];
    this.assignColor();
  }

  private assignColor() {
    this._color = this._tags.length > 0 ? new HSLA(this._tags[0].HSLA.hue, this._tags[0].HSLA.saturation, 60) : new HSLA(0, 0, 60);
  }

  public get color() {
    return this._color.toString();
  }

  public get tags() {
    return this._tags;
  }

  public addTag(tag: Tag): Tag {
    let found = this._tags.find(x => x.name == tag.name);
    if(found) {
      return found;
    } else {
      this._tags.push(tag);
      this._tags = naturalSort(this._tags, ['name']);
      this.assignColor();
      return tag;
    }
  }

  public removeTag(tag: Tag) {
    this._tags = this._tags.filter(x => x.name != tag.name);
    this.assignColor();
  }

  public toString() {
    return `new Card('${this.term}', '${this.definition}'${this._tags.length > 0 ? `, [this._tags${JSON.stringify(this._tags.map(x => extractNumeral(x.name) - 1))}]`  : ''}${this.color ? `, '${this.color}'`: ''}`;
  }
}

export class Tag {
  private _color: HSLA;

  constructor(public name: string, color?: HSLA) {
    this._color = color || this.generateColor();
  }

  private generateColor() {
    let steps = 20;
    let inverval = 360 / steps;
    return new HSLA(Math.floor(Math.random() * steps) * inverval, 100, 20);
  }

  public get color() {
    return this._color.toString();
  }

  public get HSLA() {
    return new HSLA(this._color.hue, this._color.saturation, this._color.lightness, this._color.opacity);
  }

  public toString() {
    return `new Tag('${this.name}', '${this.color}')`
  }
}

export class HSLA {
  constructor(public hue: number, public saturation?: number, public lightness?: number, public opacity?: number) {
    if (hue < 0) this.hue = 0;
    else if (hue > 360) this.hue = 360;

    if (saturation === undefined) this.saturation = 100;
    else if (saturation < 0) this.saturation = 0;
    else if (saturation > 100) this.saturation = 100;

    if (lightness === undefined) this.lightness = 100;
    else if (lightness < 0) this.lightness = 0;
    else if (lightness > 100) this.lightness = 100;

    if (opacity === undefined) this.opacity = 1;
    else if (opacity < 0) this.opacity = 0;
    else if (opacity > 1) this.opacity = 1;
  }

  public toString() {
    return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.opacity})`;
  }
}

export function naturalSort(arr: any[], property: any[]): any {
  let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
  return arr.sort((a, b) => collator.compare(getNestedObject(a, property), getNestedObject(b, property)));
}