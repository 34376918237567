<svg
  width="400"
  height="100"
  viewBox="0 0 400 100"
  version="1.1"
  id="svg5"
  xml:space="preserve"
  inkscape:version="1.2.2 (732a01da63, 2022-12-09)"
  sodipodi:docname="time-circle.svg"
  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg">
  <g style="display: none" id="g361">
    <g id="timer-template" style="display: inline">
      <circle
        id="timer-circle"
        class="timer-circle stroke-5"
        r="47.5"
        cy="50"
        cx="-50"
        transform="rotate(-90)" />
      <circle
        id="ellapsed-circle"
        cx="50"
        cy="50"
        r="47.5"
        class="timer-circle stroke-ellapsed" />
    </g>
  </g>
  <ng-container *ngFor="let food of ingredients; let i = index">
    <g
      id="{{ food.name }}-timer"
      [class]="food.getClass()"
      [attr.transform]="'translate(' + i * 100 + ')'"
      (click)="food.start($event)">
      <use xlink:href="#ellapsed-circle" />
      <circle
        style="stroke-linecap: round"
        class="timer-circle stroke-5"
        r="47.5"
        cy="50"
        cx="-50" />
      <circle
        class="timer-circle stroke-5"
        r="47.5"
        cy="50"
        cx="-50" />
      <text style="fill: white; stroke:none; font-size: 0.5em;" transform="translate(10 50)">
{{food.name}}
{{food.time}}
{{food.getClass()}}</text>
    </g>
  </ng-container>
</svg>
