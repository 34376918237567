import { Component, HostListener } from '@angular/core';
import { NavDrip, viewBox } from '../../SVG';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  public pages: {name: string, svg: NavDrip}[];
  private _viewBox: viewBox;
  public viewBox: string;
  constructor() {
    this._viewBox = {x: 0, y: 0, width: 100, height: 20};
    this.viewBox = `${this._viewBox.x} ${this._viewBox.y} ${this._viewBox.width} ${this._viewBox.height}`;
    this.drawBars(Math.round(window.innerWidth / 64));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.drawBars(Math.round(event.target.innerWidth / 64));
  }

  private drawBars(bars: number) {
    this.pages = [{ 
      name: 'home', 
      svg: new NavDrip(this._viewBox, bars, false)
    }, {
      name: 'bio', 
      svg: new NavDrip(this._viewBox, bars, false)
    }, {
      name: 'contact',
      svg: new NavDrip(this._viewBox, bars, false)
    }, {
      name: 'experiments',
      svg: new NavDrip(this._viewBox, bars, true)
    }];
  }
}