import { Component, ViewChild } from '@angular/core';
import { ExperimentsHostDirective } from './experiments-host.directive';
import { ExperimentsItem } from './experiments-item';
import { ExperimentsComponent } from './experiments.component';
import { ExperimentsService } from './experiments.service';
import { PaletteComponent } from './palette/palette.component';

@Component({
  selector: 'experimentsCollection',
  templateUrl: './experimentsCollection.component.html',
  styleUrls: ['./experimentsCollection.component.scss']
})
export class ExperimentsCollectionComponent {
  public experiments: ExperimentsItem[];
  constructor(private experimentsService: ExperimentsService) { }

  ngOnInit(): void {
    this.experiments = this.experimentsService.getExperiments();
    this.loadComponents();
  }

  @ViewChild(ExperimentsHostDirective, {static: true}) experimentHost!: ExperimentsHostDirective;

  loadComponents() {
    const viewContainerRef = this.experimentHost.viewContainerRef;
    viewContainerRef.clear();

    this.experiments.forEach(x => {
      const componentRef = viewContainerRef.createComponent<ExperimentsComponent>(x.component);
      componentRef.instance.title = x.title;
    });
  }
}