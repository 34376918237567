<form>
  <div class="form-group">
    <label for="email">email</label>
    <input type="email" id="email" placeholder="someone@example.com" required/>
  </div>
  <div class="form-group">
    <label for="feedback">feedback</label>
    <textarea type="textarea" id="feedback" rows="5" cols="22" placeholder="enter your comments here" required></textarea>
  </div>
  <button type="submit" class="btn btn-success disabled" title="still working on that one" [disabled]="true">submit</button>
</form>