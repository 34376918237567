import { Component, OnInit } from '@angular/core';
import { Card, FlashcardsService, Tag, naturalSort } from './flashcards.service';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-flashcards',
  templateUrl: './flashcards.component.html',
  styleUrls: ['./flashcards.component.scss']
})
export class FlashcardsComponent implements OnInit {
  public cards: Card[];
  public current: number = 0;
  public studies: Study[];
  public studyMode: boolean = false;
  public guess = '';
  public correction = false;
  public editCard: boolean = false;
  public workingTags: Tag[] = [];
  public editIndex: number = -1;
  public search: string = '';
  
  private workingCard: FormGroup;

  constructor(private flashcardService: FlashcardsService, private fb: FormBuilder) {
    this.cards = this.flashcardService.getCards();
    this.current = 0;
    this.studies = this.cards.map((x, i) => {
      return new Study(i);
    });
    this.shuffle(this.studies);
  }

  public addTag(card: Card) {
    let name = prompt('Add Tag', this.flashcardService.getLastTag());
    if(name) {
      let tag = this.flashcardService.addTag(new Tag(name));
      card.addTag(tag);
      naturalSort(this.cards, ['tags', 0, 'name']);
    }
  }
  
  ngOnInit() {
    this.workingCard = this.fb.group({
      term: ['', Validators.required],
      definition: ['', Validators.required]
    });
  }

  public removeTag(card: Card, tag: Tag) {
    card.removeTag(tag);
    naturalSort(this.cards, ['tags', 0, 'name']);
  }

  public addWorkingTag() {
    let name = prompt('Add Tag', this.flashcardService.getLastTag());
    if(name) {
      let tag = this.flashcardService.addTag(new Tag(name));
      this.workingTags.push(tag);
      naturalSort(this.workingTags, ['name']);
    }
  }

  removeWorkingTag(tag: Tag) {
    const index = this.workingTags.indexOf(tag);
    if (index > -1) {
      this.workingTags.splice(index, 1);
    }
  }

  public addCard() {
    this.editCard = true;
  }

  public edit(card: Card, index: number) {
    this.editIndex = index;
    this.workingCard.patchValue({
      term: card.term,
      definition: card.definition
    })
    this.workingTags = card.tags.slice();
    this.editCard = true;
    window.scrollTo(0, 0);
  }

  public deleteCard(card: Card, index: number) {
    if(confirm(`Are you sure you want to delete "${card.term}"?`) === true) {
      this.cards.splice(index, 1);
    }
  }

  public filteredCards(): Card[] {
    let ciSearch = this.search.toLowerCase();
    if(!this.search) return this.cards;
    return this.cards.filter(card => {
      return card.definition.toLowerCase().search(ciSearch) !== -1
        || card.term.search(ciSearch) !== -1;
    });
  }

  public match(card: Card): boolean {
    return card.definition.search(this.search) !== -1
      || card.term.search(this.search) !== -1;
  }

  public cancelEdit() {
    this.editCard = false;
    this.editIndex = -1;
    this.workingTags = [];
  }

  onSubmit(f: NgForm) {
    if(this.editIndex > -1) {
      this.cards[this.editIndex] = new Card(f.value.term, f.value.definition, this.workingTags);
    } else {
      this.cards.push(new Card(f.value.term, f.value.definition, this.workingTags));
      naturalSort(this.cards, ['tags', 0, 'name']);
    }
    this.editCard = false;
    this.workingTags = [];
    f.reset();
    this.save();
  }

  public check(target: Study) {
    target.showing = true;
    target.pass = this.guess === this.getTarget().definition;
    this.correction = !target.pass;
  }

  public getTarget(): Card {
    return this.cards[this.studies[this.current].index];
  }

  public next() {
    this.guess = '';
    this.studies[this.current].showing = false;
    this.correction = false;
    this.current++;
  }

  public swap() {
    this.studies[this.current].pass = !this.studies[this.current].pass;
  }

  public speak() {
    throw new Error('speak() not implemented!');
    return {
      Engine: "nueral",
      LanguageCode: "ko-KR",
      OutputFormat: "mp3",
      SpeechMarkTypes: [ "string" ],
      Text: this.getTarget().term,
      TextType: "text",
      VoiceId: "Seoyeon"
   }
  }

  public export() {
    this.flashcardService.export(this.cards);
  }

  public save() {
    this.flashcardService.save(this.cards);
  }
  
  public toggleMode() {
    this.studyMode = !this.studyMode;
    if(this.studyMode) {
      this.current = 0;
      this.shuffle(this.studies);
    }
  }

  public shuffle<T>(array: T[]) {
    let m = array.length;
    let t: T;
    let i: number;
  
    // While there remain elements to shuffle…
    while (m) {
  
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
  
      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
  
    return array;
  }
}

class Study {
  constructor(public index: number, public pass: boolean = false, public showing: boolean = false) {

  }
}