import { Component, Input } from '@angular/core';
import { ExperimentsComponent } from '../experiments.component';

@Component({
  selector: 'experiment-palette',
  templateUrl: './palette.component.html',
  styleUrls: ['./palette.component.scss']
})
export class PaletteComponent implements ExperimentsComponent {
  @Input() title: string;
  public palette: string[][][];

  constructor() {
    let variableNames: variableNames[] = [
      { variable: 'green', friendlyName: 'lime' },
      { variable: 'yellow', friendlyName: 'mango'},
      { variable: 'red', friendlyName: 'raspberry'}
    ];
    this.palette =  variableNames.map(x => {
      return [
        [x.variable, x.friendlyName],
        [`light-${x.variable}`, 'lighter'],
        [`lightest-${x.variable}`, 'lightest']
      ];
    });
  }
}

interface variableNames {
  variable: string;
  friendlyName: string;
}