export class NavDrip {
  private bars: number[];
  private stateDef: {end: number, velocity: number, offset: number}[];
  private rainTimer = false;
  constructor(private viewBox: viewBox, private barsCount, private special: boolean = false, ) {
    this.bars = new Array(this.barsCount);
    this.stateDef = [];
    this.bars.fill(0);
  }
  public getPath(): {d: string, special: boolean, offset: number}[] {
    let spacing = this.viewBox.width / this.barsCount;
    let initial = spacing / 2.0;
    let path = [];
    for(let i = 0; i < this.barsCount; i++) {
      path.push({d: `M ${initial + spacing*(i)} 0 l 0 ${this.bars[i]}`, special: this.special, offset: this.special && this.stateDef.length > 0 ? this.stateDef[i].offset : undefined});
    }
    return path;
  }

  htmlTrigger(active: boolean) {
    this.rainTimer = active;
    if(active)
      this.rain();
    let oldDef = this.stateDef;
    this.stateDef = [];
    if(active) {
      this.bars = new Array(this.barsCount);
      this.bars.fill(0);
      this.bars.forEach((x, index) => {
        let end = Math.floor(Math.random() * 15) + 5;
        this.stateDef.push({end: end, velocity: Math.floor(Math.random() * 10) / 20.0 + .8, offset: end});
      });
    }
    else {
      this.bars.forEach((x, index) => {
        this.stateDef.push({end: 0, velocity: this.bars[index] / -10.0, offset: oldDef.length > 0 ? oldDef[index].end : 0});
      });
    }
    let allzero = true;
    this.stateDef.forEach(x => {
      if(x.velocity !== 0) {
        allzero = false;
      }
    })
    if(!allzero) {
      this.transition(active 
        ? (current: number, index: number) => { return current < this.stateDef[index].end} 
        : (current: number, index: number) => { return current > this.stateDef[index].end});
    }
  }
  transition(condition: (current: number, index: number) => boolean) {
    for(let i = 0; i < this.bars.length; i++) {
      if(condition(this.bars[i], i)) {
        this.bars[i] += this.stateDef[i].velocity;
      } else {
        this.bars[i] = this.stateDef[i].end;
      }
    }
    let allfinished = true;
    this.bars.forEach((x, index) => {
      if(x !== this.stateDef[index].end) {
        allfinished = false;
      }
    })
    if(!allfinished) {
      setTimeout(() => {
        this.transition(condition);
      }, 10);
    }
  }

  rain() {
    if(this.rainTimer) {
      setTimeout(() => {
        this.stateDef.forEach((x) => {
          x.offset -= 0.25 % x.end;
        });
        this.rain();
      }, 10);
    }
  }
}
export interface viewBox {
  x: number;
  y: number;
  width: number;
  height: number;
}