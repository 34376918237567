import { Component } from '@angular/core';

@Component({
  selector: 'app-sot-fishing',
  templateUrl: './sot-fishing.component.html',
  styleUrls: ['./sot-fishing.component.scss']
})
export class SotFishingComponent {
  public minimum: number = 0;
  public splashtails: fish[] = [
    new fish('ruby', 'splashtail', false, 75,100,115, 15),
    new fish('sunny', 'splashtail', false, 150,205,225, 35),
    new fish('indigo', 'splashtail', false, 255,305,340, 50),
    new fish('umber', 'splashtail', false, 375,505,565, 85),
    new fish('seafoam', 'splashtail', false, 150,205,225, 35)];
  public trophy_splashtails: fish[] = [
    new fish('ruby', 'splashtail', true, 190, 255, 285, 45),
    new fish('sunny', 'splashtail', true, 375, 505, 565, 85),
    new fish('indigo', 'splashtail', true, 565, 765, 850, 130),
    new fish('umber', 'splashtail', true, 940, 1270, 1410, 210),
    new fish('seafoam', 'splashtail', true, 375, 505, 565, 80)];

  public pondies: fish[] = [ 
    new fish('charcoal', 'pondie', false, 75, 100, 115, 15),
    new fish('orchid', 'pondie', false, 150, 205, 225, 35),
    new fish('bronze', 'pondie', false, 255, 305, 340, 50),
    new fish('bright', 'pondie', false, 375, 505, 565, 85),
    new fish('moonsky', 'pondie', false, 150, 205, 225, 35)];
  public trophy_pondies: fish[] = [
    new fish('charcoal', 'pondie', true, 190, 255, 285, 45),
    new fish('orchid', 'pondie', true, 375, 505, 565, 85),
    new fish('bronze', 'pondie', true, 565, 765, 850, 130),
    new fish('bright', 'pondie', true, 940, 1270, 1410, 210),
    new fish('moonsky', 'pondie', true, 375, 505, 565, 85)];

  public islehoppers: fish[] = [ 
    new fish('stone', 'islehopper', false, 105, 140, 160, 25),
    new fish('moss', 'islehopper', false, 105, 140, 160, 25),
    new fish('honey', 'islehopper', false, 105, 140, 160, 25),
    new fish('raven', 'islehopper', false, 1050, 1420, 1575, 235),
    new fish('amethyst', 'islehopper', false, 105, 140, 160, 25)];
  public trophy_islehoppers: fish[] = [
    new fish('stone', 'islehopper', true, 265, 360, 400, 60),
    new fish('moss', 'islehopper', true, 265, 360, 400, 60),
    new fish('honey', 'islehopper', true, 265, 360, 400, 60),
    new fish('raven', 'islehopper', true, 2625, 3545, 3940, 590),
    new fish('amethyst', 'islehopper', true, 265, 360, 400, 60)];

  public ancientscales: fish[] = [ 
    new fish('almond', 'ancientscales', false, 225, 305, 340, 50),
    new fish('sapphire', 'ancientscales', false, 300, 405, 450, 70),
    new fish('smoke', 'ancientscales', false, 375, 505, 565, 85),
    new fish('bone', 'ancientscales', false, 3000, 4050, 4500, 675),
    new fish('starshine', 'ancientscales', false, 300, 405, 450, 70)];
  public trophy_ancientscales: fish[] = [
    new fish('almond', 'ancientscales', true, 565, 765, 850, 130),
    new fish('sapphire', 'ancientscales', true, 750, 1015, 1125, 170),
    new fish('smoke', 'ancientscales', true, 940, 1270, 1410, 210),
    new fish('bone', 'ancientscales', true, 7500, 10125, 11250, 1690),
    new fish('starshine', 'ancientscales', true, 750, 1015, 1125, 170)];

  public plentifins: fish[] = [ 
    new fish('olive', 'plentifin', false, 225, 305, 340, 50),
    new fish('amber', 'plentifin', false, 300, 405, 450, 70),
    new fish('cloudy', 'plentifin', false, 375, 505, 565, 85),
    new fish('bonedust', 'plentifin', false, 3000, 4050, 4500, 675),
    new fish('watery', 'plentifin', false, 300, 405, 450, 70)];
  public trophy_plentifins: fish[] = [
    new fish('olive', 'plentifin', true, 565, 765, 850, 130),
    new fish('amber', 'plentifin', true, 750, 1015, 1125, 170),
    new fish('cloudy', 'plentifin', true, 940, 1270, 1410, 210),
    new fish('bonedust', 'plentifin', true, 7500, 10125, 11250, 1690),
    new fish('watery', 'plentifin', true, 750, 1015, 1125, 170)];

  public wildsplashes: fish[] = [ 
    new fish('russet', 'wildsplash', false, 225, 305, 340, 50),
    new fish('sandy', 'wildsplash', false, 300, 405, 450, 70),
    new fish('ocean', 'wildsplash', false, 375, 505, 565, 85),
    new fish('muddy', 'wildsplash', false, 3000, 4050, 4500, 675),
    new fish('coral', 'wildsplash', false, 300, 405, 450, 70)];
  public trophy_wildsplashes: fish[] = [
    new fish('russet', 'wildsplash', true, 565, 765, 850, 130),
    new fish('sandy', 'wildsplash', true, 750, 1015, 1125, 170),
    new fish('ocean', 'wildsplash', true, 940, 1270, 1410, 210),
    new fish('muddy', 'wildsplash', true, 7500, 10125, 11250, 1690),
    new fish('coral', 'wildsplash', true, 750, 1015, 1125, 170)];

  public devilfishes: fish[] = [ 
    new fish('ashen', 'devilfish', false, 225, 305, 340, 50),
    new fish('seashell', 'devilfish', false, 300, 405, 450, 70),
    new fish('lava', 'devilfish', false, 375, 505, 565, 85),
    new fish('forsaken', 'devilfish', false, 3000, 4050, 4500, 675),
    new fish('firelight', 'devilfish', false, 300, 405, 450, 70)];
  public trophy_devilfishes: fish[] = [
    new fish('ashen', 'devilfish', true, 565, 765, 850, 130),
    new fish('seashell', 'devilfish', true, 750, 1015, 1125, 170),
    new fish('lava', 'devilfish', true, 940, 1270, 1410, 210),
    new fish('forsaken', 'devilfish', true, 7500, 10125, 11250, 1690),
    new fish('firelight', 'devilfish', true, 750, 1015, 1125, 170)];

  public battlegills: fish[] = [ 
    new fish('jade', 'battlegills', false, 375, 505, 565, 85),
    new fish('sky', 'battlegills', false, 450, 610, 675, 100),
    new fish('rum', 'battlegills', false, 525, 710, 790, 120),
    new fish('sand', 'battlegills', false, 3750, 5065, 5625, 845),
    new fish('bittersweet', 'battlegills', false, 450, 610, 675, 100)];
  public trophy_battlegills: fish[] = [
    new fish('jade', 'battlegills', true, 940, 1270, 1410, 210),
    new fish('sky', 'battlegills', true, 1125, 1520, 1690, 225),
    new fish('rum', 'battlegills', true, 1315, 1775, 1975, 295),
    new fish('sand', 'battlegills', true, 9375, 12655, 14065, 2110),
    new fish('bittersweet', 'battlegills', true, 1125, 1520, 1690, 225)];

  public wreckers: fish[] = [ 
    new fish('rose', 'wrecker', false, 375, 505, 565, 85),
    new fish('sun', 'wrecker', false, 450, 610, 675, 100),
    new fish('blackcloud', 'wrecker', false, 525, 710, 790, 120),
    new fish('snow', 'wrecker', false, 3750, 5065, 5625, 845),
    new fish('moon', 'wrecker', false, 450, 610, 675, 100)];
  public trophy_wreckers: fish[] = [
    new fish('rose', 'wrecker', true, 940, 1270, 1410, 210),
    new fish('sun', 'wrecker', true, 1125, 1520, 1690, 225),
    new fish('blackcloud', 'wrecker', true, 1315, 1775, 1975, 295),
    new fish('snow', 'wrecker', true, 9375, 12655, 14065, 2110),
    new fish('moon', 'wrecker', true, 1125, 1520, 1690, 225)];

  public stormfishes: fish[] = [ 
    new fish('ancient', 'stormfish', false, 450, 610, 675, 100),
    new fish('shores', 'stormfish', false, 450, 610, 675, 100),
    new fish('wild', 'stormfish', false, 450, 610, 675, 100),
    new fish('shadow', 'stormfish', false, 4500, 6075, 6750, 1015),
    new fish('twilight', 'stormfish', false, 450, 610, 675, 100)];
  public trophy_stormfishes: fish[] = [
    new fish('ancient', 'stormfish', true, 1125, 1520, 1690, 225),
    new fish('shores', 'stormfish', true, 1125, 1520, 1690, 225),
    new fish('wild', 'stormfish', true, 1125, 1520, 1690, 225),
    new fish('shadow', 'stormfish', true, 11250, 15190, 16875, 1400),
    new fish('twilight', 'stormfish', true, 1125, 1520, 1690, 225)];

  public basicFish: fish[] =
    this.splashtails.concat(
      this.pondies,
      this.islehoppers,
      this.ancientscales,
      this.plentifins,
      this.wildsplashes,
      this.devilfishes,
      this.battlegills,
      this.wreckers,
      this.stormfishes)
  generateId(name: string, breed: string, suffix: string) {
    return `${name}-${breed}--${suffix}`;
  }
  generateMaskRef(name: string, breed: string, suffix: string) {
    return `url(#${this.generateId(name, breed, suffix)})`;
  }
  generateXlinkHref(name: string, breed: string, suffix: string) {
    return `#${this.generateId(name, breed, suffix)}`;
  }
}

class fish {
  constructor(
    public name: string, 
    public breed: string, 
    public trophy: boolean, 
    Raw: number, 
    Undercooked: number, 
    Cooked: number, 
    Burnt: number){
    this.value = {
      raw: Raw,
      undercooked: Undercooked,
      cooked: Cooked,
      burnt: Burnt
    }
  }
  value: {
    raw: number
    undercooked: number
    cooked: number
    burnt: number
  }
}
