
<button class="btn btn-dark" (click)="toggleMode()">{{!studyMode ? 'Study' : 'Review'}}</button>
<ng-container *ngIf="studyMode">
    <div class="flashcard-study card m-1"  [class.pass]="studies[current].showing && studies[current].pass" [class.fail]="studies[current].showing && !studies[current].pass">
        <div class="card-body">
            <div class="card-title text-center align-middle term" [style.color]="getTarget().color">{{getTarget().term}}<button class="btn btn-dark" (click)="speak()">🔊</button></div>
            <input *ngIf="!studies[current].showing" type="text" [(ngModel)]="guess"/>
            <div *ngIf="studies[current].showing" class="definition text-center">{{getTarget().definition}}</div>
            <button class="btn btn-dark" (click)="check(studies[current])">Check</button>
            <button class="btn btn-dark" (click)="next()">{{ studies[current].showing ? 'Next' : 'Skip' }}</button>
            <ng-container *ngIf="correction">
                <span *ngIf="!studies[current].pass" class="correction">Got it right? <button class="btn text-warning" (click)="swap()">Mark as correct</button></span>
                <span *ngIf="studies[current].pass" class="correction">Marked as correct. <button class="btn text-warning" [class.fail]="studies[current].pass" [class.pass]="!studies[current].pass"(click)="swap()">Mark as incorrect</button></span>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!studyMode">
    <div>
        <input class="form-control" type="search" name="q" placeholder="Search" [(ngModel)]="search"/>
        <button class="btn btn-dark" (click)="export()">Export</button>
        <button class="btn btn-dark" (click)="addCard()">Add Card</button>
    </div>
    <ng-container *ngIf="editCard">
        <div class="flashcard card m-1 d-block">
            <div class="card-body">
                <div class="tags text-left">
                    <div class="tag" *ngFor="let wtag of workingTags" [style.background-color]="wtag.color">{{wtag.name}}<button class="btn remove-tag" title="Remove Tag" (click)="removeWorkingTag(wtag)"></button></div>
                    <button class="btn btn-sm add-tag" title="Add Tag" (click)="addWorkingTag()"></button>
                </div>
                <form [formGroup]="workingCard" (ngSubmit)="onSubmit(workingCard)" novalidate>
                    <input class="card-title text-center term" type="text" name="term" formControlName="term" placeholder="Term"/>
                    <input class="text-center definition" type="text" formControlName="definition" name="definition" placeholder="Definition"/>
                    <button class="btn btn-dark m-1" type="submit">Save</button>
                    <button class="btn btn-dark m-1" (click)="cancelEdit()">Cancel</button>
                </form>
            </div> 
        </div>
    </ng-container>
    <div class="flashcard card m-1" *ngFor="let card of filteredCards(); let i = index">
        <div class="card-body">
            <div class="text-end">
                <button class="btn btn-dark" title="Edit Card" (click)="edit(card, i)">✏️</button>
                <button class="btn btn-dark" title="Delete Card" (click)="deleteCard(card, i)">🗑️</button>
            </div>
            <div class="card-title text-center term" [style.color]="card.color">{{card.term}}</div>
            <div class="definition text-center">{{card.definition}}</div>
            <div class="tags text-left">
                <div class="tag" *ngFor="let tag of card.tags" [style.background-color]="tag.color">{{tag.name}}<button class="btn remove-tag" title="Remove Tag" (click)="removeTag(card, tag)"></button></div>
                <button class="btn btn-sm add-tag" title="Add Tag" (click)="addTag(card)"></button>
            </div>
        </div>
    </div>
</ng-container>

