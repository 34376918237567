import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BioComponent } from './bio/bio.component';
import { ContactComponent } from './contact/contact.component';
import { ExperimentsCollectionComponent } from './experiments/experimentsCollection.component';
import { FlashcardsComponent } from './experiments/flashcards/flashcards.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'bio', component: BioComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'experiments', component: ExperimentsCollectionComponent },
  { path: 'experiments/flashcards', component: FlashcardsComponent }
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
