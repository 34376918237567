import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { BioComponent } from './bio/bio.component';
import { ContactComponent } from './contact/contact.component';
import { BioService } from './bio.service';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { ExperimentsCollectionComponent } from './experiments/experimentsCollection.component';
import { PaletteComponent } from './experiments/palette/palette.component';
import { ExperimentsHostDirective } from './experiments/experiments-host.directive';
import { SotFishingComponent } from './experiments/sot-fishing/sot-fishing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SotCookingComponent } from './experiments/sot-cooking/sot-cooking.component';
import { FlashcardsComponent } from './experiments/flashcards/flashcards.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    BioComponent,
    HomeComponent,
    ContactComponent,
    ExperimentsCollectionComponent,
    PaletteComponent,
    ExperimentsHostDirective,
    SotFishingComponent,
    SotCookingComponent,
    FlashcardsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ BioService ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }