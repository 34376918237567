import { Injectable } from '@angular/core';
import { ExperimentsItem } from './experiments-item';
import { PaletteComponent } from './palette/palette.component';
import { SotCookingComponent } from './sot-cooking/sot-cooking.component';
import { SotFishingComponent } from './sot-fishing/sot-fishing.component';
import { FlashcardsComponent } from './flashcards/flashcards.component';

@Injectable({
  providedIn: 'root'
})
export class ExperimentsService {
  constructor() { }

  getExperiments() {
    return [
      new ExperimentsItem(PaletteComponent, 'Palette'),
      new ExperimentsItem(SotFishingComponent, 'Sea of Thieves Fishing'),
      new ExperimentsItem(SotCookingComponent, 'Sea of Thieves Cooking'),
      new ExperimentsItem(FlashcardsComponent, 'Flashcards')
    ]
  }
}
