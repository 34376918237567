import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-sot-cooking',
  templateUrl: './sot-cooking.component.html',
  styleUrls: ['./sot-cooking.component.scss'],
})
export class SotCookingComponent {
  public ingredients: food[] = [
    new food("fish", 30, 40, 80),
    new food("trophy-fish", 80, 90, 180),
    new food("meat", 50, 60, 120),
    new food("beast", 100, 120, 240)
  ]

  constructor() { }
}

class food {
  public player: AnimationPlayer;
  private animBuilder: AnimationBuilder;

  constructor(public name: string, public undercooked: number, public cooked: number, public burnt: number) { 
    this.reset();
  }
  public time: number;
  public cook: cook;
  public start(event): void {
    this.reset();
    let scale = 1000;
    let startTime = Date.now();
    let tick = setInterval(() => {
      this.time = Math.floor((Date.now() - startTime) / 1000);
    }, scale);
    event.target.style.animation = `clock-animation ${this.burnt}s linear 1`;
    setTimeout(() => {
      this.cook = cook.undercooked;
      setTimeout(() => {
        this.cook = cook.cooked;
        setTimeout(() => {
          this.cook = cook.burnt;
          clearInterval(tick);
        }, (this.burnt - this.cooked) * scale);
      }, (this.cooked - this.undercooked) * scale);
    }, (this.undercooked) * scale);
  }
  public reset() {
    this.time = 0;
    this.cook = cook.raw;
  }
  public getClass(): string {
    return cook[this.cook];
  }
}

enum cook {
  raw,
  undercooked,
  cooked,
  burnt
}