import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BioService {

  constructor() { }

  public getBio(): string {
    return "I'm Simon Puzankov. I enjoy building software that's intuitive and appealing to users. " + 
    "From an early age, I've been using software to create things that inspire people " + 
    "about the possibilities of technology, so I turned it into a career. " +
    "There's no shortage of interesting areas to explore. You can see some of my " +
    "ongoing experiments right on this site!"
  }
}
